import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Gewichtung1() {
  const question = {
    title: 'Gewichtung - 1. Teil',
    headline: 'Den 1.Teil hast du schon fast geschafft!',
    subline: (
      <>
        Bitte wähle hier noch <strong>zwei</strong> Dinge aus, die dir bei
        deinen Patienten besonders wichtig sind:
      </>
    ),
  }

  const answers = [
    {
      name: 'Wichtiger',
      id: 'Geschlecht',
      value: 'Geschlecht',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Alter',
      value: 'Alter',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Pflegeintensität',
      value: 'Pflegeintensität',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'erkrankt',
      value: 'Akut oder chronisch erkrankt',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Krankheitsverlauf',
      value: 'Krankheitsverlauf',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Bewusstsein',
      value: 'bei Bewusstsein',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Liegedauer',
      value: 'Liegedauer',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Beziehung',
      value: 'Beziehung aufbauen',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Palliativpflege',
      value: 'Palliativpflege',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-blue-waves bg-turkis">
        <Navigation />

        <section className="quiz pt-0">
          <div className="row text-center">
            <img
              className="mx-auto"
              data-src="/img/handlettering/handlettering-yay-1.svg"
              src="/img/handlettering/handlettering-yay-1.svg"
              alt="Yay!"
              title="Yay!"
              width="400"
            />
            <MatchingQuestion
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="multi"
              answers={answers}
              limited
              color="yellow"
              index={'g1'}
            />
          </div>
        </section>

        <MatchingNavigation
          color="white"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-10`}
          prev={`${AREA_CARE_PATH}/spielen/frage-9`}
          index={'g1'}
        />

        <Icon position="1" name="doctor" />
        <Icon position="2" name="conversation" />
        <Icon position="3" name="speachbubbles" />
        <Icon position="4" name="gender" />
      </main>
    </>
  )
}
